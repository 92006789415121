import gql from 'graphql-tag'

export const NEW_ROOM_MEDIA_SUBSCRIPTION = gql`
  subscription ($participantSlug: String) {
    newTwilioRecording (participantSlug: $participantSlug) {
      id
      compositionUrl
      videoUrl
      audioUrl
      roomSid
      participantSid
    }
  }
`

export const CURRENT_USER_NEW_ROOM_MEDIA_SUBSCRIPTION = gql`
  subscription  {
    newTwilioRecording (participantSlug: null) {
      id
      compositionUrl
      videoUrl
      audioUrl
    }
  }
`